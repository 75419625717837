/* @jsx jsx */
import { jsx } from "theme-ui";
import { navigate, PageProps } from "gatsby";
import qs from "querystring";
import { Layout } from "../components/Layout";
import SEO from "../components/Seo";
import config from "../config";

import { H2, P } from "../components/Typography";
import { Button } from "../components/Button";
import { ReactComponent as GoogleLogo } from "../../static/google.svg";
import { useGetCurrentUserQuery } from "../graphql/queries/_generated";
import { useEffect } from "react";
import LoginForm from "../components/LoginForm";
import { Flex } from "../components/Flex";

const RedirectToApp = () => {
  const [{ data }] = useGetCurrentUserQuery();

  useEffect(() => {
    if (data?.currentUser) {
      navigate(`/app`);
    }
  }, [data?.currentUser]);

  return null;
};

export default ({ location }: PageProps) => {
  const query = qs.parse(location.search.substring(1));
  const email = typeof query.e === "string" ? atob(query.e) : "unknown email";

  useEffect(() => {
    const checkAuth = () => {
      fetch(`${config.backendUrl}/auth/magiclink/status${location.search}`)
        .then((res) => res.json())
        .then((json) => {
          if (json.success) {
            window.location.assign(json.url);
          } else {
            setTimeout(checkAuth, 1000);
          }
        });
    };

    checkAuth();
  }, []);

  return (
    <Layout>
      <SEO title="Check your inbox" description="Feedback Fish - Login" />

      <RedirectToApp />
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          px: 4,
          textAlign: "center",
        }}
      >
        <Flex
          flexDirection="column"
          gap={2}
          sx={{
            borderWidth: `1px`,
            borderStyle: `solid`,
            borderColor: `grey.3`,
            p: 4,
            boxShadow: "0 4px 5px rgba(0,0,0,0.04)",
            borderRadius: `10px`,
            minWidth: `350px`,
          }}
        >
          <H2>Check your email</H2>
          <p>
            We sent a magic link to {email}!<br />
            Click on it to confirm the log in.
          </p>
          <p sx={{ color: `gray.5` }}>Verification code: {query.c}</p>
        </Flex>
      </div>
    </Layout>
  );
};
